<template>
  <b-row v-if="modules && !!modules['invoices'] && item">
    <b-col :md="4" :lg="4">
      <b-card v-if="role !== 'USER'" :title="`${ item.refNumber }`">
        <b-button-toolbar id="state_toolbar">
          <b-button-group class="mx-auto">
            <b-dropdown :disabled="item.status==='CANCELLED' || item.status=='CONFIRMED'" :variant="getStatusVariant(item.status)" :text="getStatusText(item.status)">
              <b-dropdown-item :disabled="item.status!=='NEW'" @click="setStatus('CONFIRMED')">
                Confirm
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :disabled="item.status==='CANCELLED' || item.status=='CONFIRMED'" @click="setStatus('CANCELLED')">
                Cancel
              </b-dropdown-item>
            </b-dropdown>
            <EmailButton v-if="item.status=='CONFIRMED' || item.status=='CLOSED'" :invoice-id="id" :booking-id="bookingId" />
          </b-button-group>
        </b-button-toolbar>
        <br>
        <b-button-toolbar>
          <PdfButton class="mx-auto" size="fa-2x" :booking-id="bookingId" :invoice-id="id" />
        </b-button-toolbar>
      </b-card>
    </b-col>
    <b-col :md="8" :lg="8">
      <Invoice :item="item" />
    </b-col>
  </b-row>
</template>

<script>
import * as _ from 'lodash';
import PdfButton from '../components/pdf-button';
import EmailButton from '../../booking_notifications/components/invoice-email.button.vue';
import Invoice from '../components/invoice';

export default {
  components: {
    Invoice,
    PdfButton,
    EmailButton
  },
  data () {
    return {
      errors: {},
      fields: [
        {
          key: 'refNumber',
          sortable: true,
          slot: true
        },
        {
          key: 'firstname',
        },
        {
          key: 'lastname',
        },
        {
          key: 'companyname',
        },
        {
          key: 'createdAt',
          sortable: true
        },
      ],
      newDefaultValues: {
        bookingId: this.bookingId
      },
      item: {},
      id: null,
      bookingId: null
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
    role () {
      return this.$store.getters.me ? this.$store.getters.me.role : 'USER';
    },
    org () {
      return this.$store.getters.org;
    },
  },
  created () {
    this.id = this.$route.params.id;
    this.bookingId = this.$route.params.bookingId;
    this.getInvoice();
  },
  methods: {
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    toUpperCase () {
      return  _.toUpperCase;
    },
    getInvoice () {
      this.$http.get(`/bookings/${this.bookingId}/invoices/${this.id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(this.err);
    },
    getStatusVariant (status) {
      switch (status) {
        case 'NEW':
          return 'info';
        case 'CONFIRMED':
          return 'warning';
        case 'CANCELLED':
          return 'danger';
        default:
          return 'dark';
      }
    },
    getStatusText (status) {
      if (!status) {
        return;
      }
      return _.upperFirst(status.toLowerCase());
    },
    setStatus (status) {
      if (!status) {
        return;
      }
      return this.$http.patch(`/bookings/${this.bookingId}/invoices/${this.id}`, { status })
        .then(() => this.getInvoice())
        .catch(this.err);
    },
  }
};
</script>
