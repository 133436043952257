<template>
  <b-button v-if="modules && !!modules['booking_notifications']" @click.prevent="emailInvoice">
    E-mail
    <b-modal ref="emailmodal" size="md" no-enforce-focus hide-header>
      <b-form-group label="">
        <b-form-group label="">
          <b-form-radio v-if="defaultEmail" v-model="emailRadio" :value="null">
            Send to default address: {{ defaultEmail }}
          </b-form-radio>
          <b-form-radio v-model="emailRadio" :value="true">
            Send email to:
          </b-form-radio>
        </b-form-group>
        <b-input v-if="emailRadio === true" v-model="emailInput" />
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="send()">
          Send
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-button>
</template>
<script>
export default {
  props: ['bookingId', 'invoiceId'],
  data () {
    return {
      defaultEmail: null,
      emailRadio: null,
      emailInput: ''
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    emailInvoice () {
      if (!this.bookingId) {
        return this.err(new Error('Invalid booking'));
      }
      this.$http.get(`bookings/${this.bookingId}`)
        .then((res) => { // eslint-disable-line
          const booking = res.data;
          if (booking.invoicesEmail) {
            this.defaultEmail = booking.invoicesEmail;
            this.emailRadio = null;
          } else {
            this.emailRadio = true;
          }
          this.$refs.emailmodal.show();
        })
        .catch(this.err);
    },
    send () {
      if (this.emailRadio === true && !this.emailInput) {
        return this.err(new Error('Please provide email address'));
      }
      const { bookingId, invoiceId } = this;
      const body = this.emailRadio === true ? { to: this.emailInput } : {};
      this.$http.post(`/bookings/${bookingId}/invoices/${invoiceId}/email`, body)
        .then(() => {
          // success
          this.$refs.emailmodal.hide();
          this.$notify({ text: 'e-mail sent.' });
        })
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  },
};
</script>
